import React, { createContext, useState, useEffect, useMemo } from "react";
import Cookies from "js-cookie";

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState({});
  const [cookies, setCookies] = useState(null);

  useEffect(() => {
    const checkLogged = Cookies.get("access") ? true : false;
    const userInfo = Cookies.get("userInfo")
      ? JSON.parse(Cookies.get("userInfo"))
      : {};
    setIsAuth(checkLogged);
    setUser(userInfo);
  }, []);

  const values = useMemo(() => ({ isAuth, setIsAuth, user, setUser, cookies, setCookies }), [
    isAuth,
    user,
    cookies
  ]);

  return (
    <UserContext.Provider value={values}>{props.children}</UserContext.Provider>
  );
};

export default UserContextProvider;