import { methodPost, methodPatch } from './methods';

export const authApi = async (query) => {
    return methodPost('/login-admin/', query);
};

export const changePassword = async (uid, token, query) => {
    return methodPost(`/passwords/recover-password/?uid=${uid}&token=${token}`, query);
};

export const changePasswordApi = async (query) => {
    return methodPost(`/passwords/change-password/`, query);
};

export const modifyUser = async (id, query) => {
    return methodPatch('/account?accountID=' + id, query);
};

export const logout = async (payload) => {
    return methodPost('/users/logout/', payload);
};
