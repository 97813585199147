import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, makeStyles, IconButton, Hidden } from '@material-ui/core';

import { MenuTwoTone, ChevronLeft } from '@material-ui/icons';
import ProfileSection from './ProfileSection';
//import Customization from './Customization';
//import NotificationSection from './NotificationSection';
import logo from './../../../assets/images/Logo_app_white.png';
import { drawerWidth } from './../../../store/constant';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1.25),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuIcon: {
        fontSize: '1.5rem',
    },
    logo: {
        width: '50px',
    },
}));

const Header = (props) => {
    const { drawerToggle } = props;
    const classes = useStyles();
    const drawer = useSelector((state) => state.drawer);
    const { drawerOpen } = drawer;

    return (
        <React.Fragment>
            <Box width={drawerWidth}>
                <Grid container justifyContent={drawerOpen ? "space-between" : "flex-start"} alignItems="center" spacing={drawerOpen ? 0 : 2}>
                    <Hidden smDown>
                        <Grid item>
                            <img className={classes.logo} src={logo} alt="Logo" />
                        </Grid>
                    </Hidden>
                    <Grid item>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={drawerToggle}
                        >
                            {drawerOpen ? <ChevronLeft /> : <MenuTwoTone className={classes.menuIcon} />}
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <div className={classes.grow} />

            {/* <SearchSection theme="light" /> */}
            {/* <Customization /> 
                <NotificationSection /> 
            */}
            <ProfileSection />
        </React.Fragment>
    );
};

export default Header;
