import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { render } from "react-dom";
import App from './layout/App';
import reducer from './store/reducer';
import config from './config';
import './assets/scss/style.scss';
import * as serviceWorker from './serviceWorker';
import UserContextProvider from "./context/UserContext";
import SettingsContextProvider from "./context/SettingsContext";
const store = createStore(reducer);


const rootElement = document.getElementById("root");

render(
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider store={store}>
            <UserContextProvider>
                <SettingsContextProvider>
                    <BrowserRouter basename={config.basename}>
                        <App />
                    </BrowserRouter>
                </SettingsContextProvider>
            </UserContextProvider>
        </Provider>
    </LocalizationProvider>, rootElement);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
