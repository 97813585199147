import * as actionTypes from './actions';

export const initialState = {
    drawerOpen: false,
};

const drawerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DRAWER_OPEN:
            return {
                ...state,
                drawerOpen: action.drawerOpen,
            };
        default:
            return state;
    }
};

export default drawerReducer;
