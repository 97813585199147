import { combineReducers } from 'redux';
import customizationReducer from './customizationReducer';
import drawerReducer from './drawerReducer';

const reducer = combineReducers({
    customization: customizationReducer,
    drawer: drawerReducer
});

export default reducer;
