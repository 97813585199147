import React, { lazy, Suspense, useContext } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import Loader from './component/Loader/Loader';
import NavMotion from './layout/NavMotion';
import MainLayout from './layout/MainLayout';
import MinimalLayout from './layout/MinimalLayout';
import { UserContext } from './context/UserContext';

const Price = lazy(() => import('./views/Application/Price/Price2'));
const AuthLogin = lazy(() => import('./views/Login'));
const ChangePassword = lazy(() => import('./views/Password'));
const DashboardDefault = lazy(() => import('./views/Dashboard/Default'));
const Posts = lazy(() => import('./views/Posts'));
const Streamings = lazy(() => import('./views/Streamings'));
const Clients = lazy(() => import('./views/Client'));
const Reloads = lazy(() => import('./views/Reloads'));
const Creators = lazy(() => import('./views/Creators'));
const Users = lazy(() => import('./views/Users'));
const Reports = lazy(() => import('./views/Reports'));
const Profile = lazy(() => import('./views/Profile'));
const Notifications = lazy(() => import('./views/Notifications'));
const Sales = lazy(() => import('./views/Sales'));
const Transactions = lazy(() => import('./views/Transactions'));
const CurrentChange = lazy(() => import('./views/CurrentChange'));
const Withdrawals = lazy(() => import('./views/Withdrawals'));
const CostsChat = lazy(() => import('./views/CostsChat'));
const InitialCoins = lazy(() => import('./views/InitialCoins'));
const CommissionType = lazy(() => import('./views/Commissions'));
const ChatCostGroup = lazy(() => import('./views/ChatCostGroup'));
const ChatGainGroup = lazy(() => import('./views/ChatGainGroup'));
const ChatActiveCostGroup = lazy(() => import('./views/ChatActiveCostGroup'));

const requireLogin = (to, from, next) => {
    if (to.meta.auth) {
        next();
    } else {
        next.redirect('/login');
    }
};

const Routes = () => {
    const location = useLocation();
    const { isAuth } = useContext(UserContext);

    return (
        <AnimatePresence>
            <GuardProvider guards={[requireLogin]} loading={Loader} error={Price}>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Redirect exact from="/" to="/home" />
                        <Route path={[]}>
                            <MinimalLayout>
                                <Switch location={location} key={location.pathname}>
                                    <NavMotion></NavMotion>
                                </Switch>
                            </MinimalLayout>
                        </Route>
                        <Route path="/login" component={AuthLogin} />
                        <Route path="/changePassword/:uid/:token" component={ChangePassword} />
                        <Route
                            path={[
                                '/home',
                                '/reloads',
                                '/creators',
                                '/users',
                                '/reports',
                                '/user/profile',
                                '/notifications',
                                '/clients',
                                '/sales',
                                '/transactions',
                                '/current-change',
                                '/withdrawals',
                                '/costs-chat',
                                '/default-coins',
                                '/commission-type',
                                '/chat-cost-group',
                                '/chat-gain-group',
                                '/chat-active-cost-group',
                            ]}
                        >
                            <MainLayout>
                                <Switch location={location} key={location.pathname}>
                                    <NavMotion>
                                        <GuardedRoute path="/home" component={DashboardDefault} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/reloads" component={Reloads} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/creators" component={Creators} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/notifications" component={Notifications} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/users" component={Users} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/clients" component={Clients} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/reports" component={Reports} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/sales" component={Sales} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/transactions" component={Transactions} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/user/profile" component={Profile} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/current-change" component={CurrentChange} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/withdrawals" component={Withdrawals} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/costs-chat" component={CostsChat} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/default-coins" component={InitialCoins} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/commission-type" component={CommissionType} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/chat-cost-group" component={ChatCostGroup} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/chat-gain-group" component={ChatGainGroup} meta={{ auth: isAuth }} />
                                        <GuardedRoute path="/chat-active-cost-group" component={ChatActiveCostGroup} meta={{ auth: isAuth }} />
                                    </NavMotion>
                                </Switch>
                            </MainLayout>
                        </Route>
                    </Switch>
                </Suspense>
            </GuardProvider>
        </AnimatePresence>
    );
};

export default Routes;
