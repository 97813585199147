import React, { createContext, useState, useEffect, useMemo } from "react";

export const SettingsContext = createContext();

const SettingsContextProvider = (props) => {
    const typeUser = [
        { 'type': 'provider', _id: '2', 'name': 'Proveedor' },
        { 'type': 'client', _id: '3', 'name': 'Cliente' },
    ];

    const statusUser = [
        { 'type': 'Inactive', _id: '0', 'name': 'Inactivo' },
        { 'type': 'Active', _id: '1', 'name': 'Activo' },
        { 'type': 'Disabled', _id: '2', 'name': 'Desabilitado' },
    ];

    const typeUserDash = [
        { 'type': 'admin', _id: 'admin', 'name': 'Administrador' },
    ];

    const values = useMemo(() => ({ typeUser, typeUserDash, statusUser }), [
        typeUser, 
        typeUserDash,
        statusUser
    ]);

    return (
        <SettingsContext.Provider value={values}>{props.children}</SettingsContext.Provider>
    );
};

export default SettingsContextProvider;