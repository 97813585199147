import NavigationOutlinedIcon from '@material-ui/icons/NavigationOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import FormatUnderlinedOutlinedIcon from '@material-ui/icons/FormatUnderlinedOutlined';
import GamesOutlinedIcon from '@material-ui/icons/GamesOutlined';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import CallToActionOutlinedIcon from '@material-ui/icons/CallToActionOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import FilterVintageOutlinedIcon from '@material-ui/icons/FilterVintageOutlined';
import FormatColorTextOutlinedIcon from '@material-ui/icons/FormatColorTextOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import ChromeReaderModeOutlinedIcon from '@material-ui/icons/ChromeReaderModeOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import EditAttributesOutlinedIcon from '@material-ui/icons/EditAttributesOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import TranslateIcon from '@material-ui/icons/Translate';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

const icons = {
    NavigationOutlinedIcon: NavigationOutlinedIcon,
    HomeOutlinedIcon: HomeOutlinedIcon,
    FormatUnderlinedOutlinedIcon: FormatUnderlinedOutlinedIcon,
    GamesOutlinedIcon: GamesOutlinedIcon,
    LoyaltyOutlinedIcon: LoyaltyOutlinedIcon,
    ForumOutlinedIcon: ForumOutlinedIcon,
    DescriptionOutlinedIcon: DescriptionOutlinedIcon,
    GridOnOutlinedIcon: GridOnOutlinedIcon,
    TableChartOutlinedIcon: TableChartOutlinedIcon,
    AccountTreeOutlinedIcon: AccountTreeOutlinedIcon,
    CallToActionOutlinedIcon: CallToActionOutlinedIcon,
    NotificationsNoneOutlinedIcon: NotificationsNoneOutlinedIcon,
    AppsOutlinedIcon: AppsOutlinedIcon,
    SmsOutlinedIcon: SmsOutlinedIcon,
    FilterVintageOutlinedIcon: FilterVintageOutlinedIcon,
    FormatColorTextOutlinedIcon: FormatColorTextOutlinedIcon,
    ContactSupportOutlinedIcon: ContactSupportOutlinedIcon,
    LayersOutlinedIcon: LayersOutlinedIcon,
    BlockOutlinedIcon: BlockOutlinedIcon,
    ChromeReaderModeOutlinedIcon: ChromeReaderModeOutlinedIcon,
    FiberManualRecordOutlinedIcon: FiberManualRecordOutlinedIcon,
    EditAttributesOutlinedIcon: EditAttributesOutlinedIcon,
    FaceOutlinedIcon: FaceOutlinedIcon,
    RadioButtonUncheckedOutlinedIcon: RadioButtonUncheckedOutlinedIcon,
    QuestionAnswerOutlinedIcon: QuestionAnswerOutlinedIcon,
    AccountCircleOutlinedIcon: AccountCircleOutlinedIcon,
    ShoppingCartOutlinedIcon: ShoppingCartOutlinedIcon,
    MailOutlineRoundedIcon: MailOutlineRoundedIcon,
    SpeakerNotesOutlinedIcon: SpeakerNotesOutlinedIcon,
    CardGiftcardOutlinedIcon: CardGiftcardOutlinedIcon,
    ErrorOutlineRoundedIcon: ErrorOutlineRoundedIcon,
    HourglassEmptyRoundedIcon: HourglassEmptyRoundedIcon,
    MonetizationOnOutlinedIcon: MonetizationOnOutlinedIcon,
    AssignmentIndOutlinedIcon: AssignmentIndOutlinedIcon,
    SecurityOutlinedIcon: SecurityOutlinedIcon,
    HelpOutlineOutlinedIcon: HelpOutlineOutlinedIcon,
    TranslateIcon: TranslateIcon,
    FormatListNumberedRtlIcon: FormatListNumberedRtlIcon,
    BusinessCenterIcon: BusinessCenterIcon,
};

export default {
    items: [
        {
            id: 'navigation',
            title: 'Dashboard',
            caption: '',
            type: 'group',
            icon: icons['NavigationOutlinedIcon'],
            children: [
                {
                    id: 'dashboard',
                    title: 'Home',
                    type: 'item',
                    icon: icons['HomeOutlinedIcon'],
                    url: '/home',
                    rols: [1, 2],
                },
                /*{
                    id: 'posts',
                    title: 'Publicaciones',
                    type: 'item',
                    icon: icons['ContactSupportOutlinedIcon'],
                    url: '/posts',
                },
                {
                    id: 'streamings',
                    title: 'Streamings',
                    type: 'item',
                    icon: icons['LayersOutlinedIcon'],
                    url: '/streamings',
                },
                {
                    id: 'perquisites',
                    title: 'Donaciones',
                    type: 'item',
                    icon: icons['DescriptionOutlinedIcon'],
                    url: '/perquisites',
                },*/
                // {
                //     id: 'creators',
                //     title: 'Creadores de contenido',
                //     type: 'item',
                //     icon: icons['GamesOutlinedIcon'],
                //     url: '/creators',
                // },
                {
                    id: 'notifications',
                    title: 'Notificaciones',
                    type: 'item',
                    icon: icons['MailOutlineRoundedIcon'],
                    url: '/notifications',
                    rols: [2],
                },
                // {
                //     id: 'clients',
                //     title: 'Clientes',
                //     type: 'item',
                //     icon: icons['AssignmentIndOutlinedIcon'],
                //     url: '/clients',
                // },
                {
                    id: 'users',
                    title: 'Usuarios',
                    type: 'item',
                    icon: icons['AccountTreeOutlinedIcon'],
                    url: '/users',
                    rols: [2],
                },
                {
                    id: 'reports',
                    title: 'Reportes',
                    type: 'item',
                    icon: icons['FormatListNumberedRtlIcon'],
                    url: '/reports',
                    rols: [2],
                },
                {
                    id: 'sales',
                    title: 'Movimiento de monedas',
                    type: 'item',
                    icon: icons['FormatListNumberedRtlIcon'],
                    url: '/sales',
                    rols: [1, 2],
                },
                {
                    id: 'transactions',
                    title: 'Compra de coins',
                    type: 'item',
                    icon: icons['FormatListNumberedRtlIcon'],
                    url: '/transactions',
                    rols: [2],
                },
                {
                    id: 'withdrawals',
                    title: 'Solicitudes de retiro',
                    type: 'item',
                    icon: icons['FormatListNumberedRtlIcon'],
                    url: '/withdrawals',
                    rols: [2],
                },
                {
                    id: 'cofiguration',
                    title: 'Configuración',
                    type: 'collapse',
                    icon: icons["FormatListNumberedRtlIcon"],
                    rols: [2],
                    children: [
                        {

                            id: 'reloads',
                            title: 'Recargas',
                            type: 'item',
                            icon: icons['CallToActionOutlinedIcon'],
                            url: '/reloads',
                        },
                        {
                            id: 'current-change',
                            title: "Cambio de moneda para retiro",
                            type: 'item',
                            icon: icons['FormatListNumberedRtlIcon'],
                            url: '/current-change',
                        },

                        // {
                        //     id: 'costs-chat',
                        //     title: 'Costos del chat',
                        //     type: 'item',
                        //     icon: icons['FormatListNumberedRtlIcon'],
                        //     url: '/costs-chat',
                        // },
                        {
                            id: 'default-coins',
                            title: 'Monedas iniciales',
                            type: 'item',
                            icon: icons['FormatListNumberedRtlIcon'],
                            url: '/default-coins',
                        },
                        // {
                        //     id: 'commission-type',
                        //     title: 'Comisiones',
                        //     type: 'item',
                        //     icon: icons['FormatListNumberedRtlIcon'],
                        //     url: '/commission-type',
                        // },
                        // {
                        //     id: 'chat-cost-group',
                        //     title: 'Grupos de Costos del Chat',
                        //     type: 'item',
                        //     icon: icons['FormatListNumberedRtlIcon'],
                        //     url: '/chat-cost-group',
                        // },
                        // {
                        //     id: 'chat-gain-group',
                        //     title: 'Grupos de Ganancias del Chat',
                        //     type: 'item',
                        //     icon: icons['FormatListNumberedRtlIcon'],
                        //     url: '/chat-gain-group',
                        // },
                        {
                            id: 'chat-active-cost-group',
                            title: 'Grupos de Costos por tiempo Activo del Chat',
                            type: 'item',
                            icon: icons['FormatListNumberedRtlIcon'],
                            url: '/chat-active-cost-group',
                        },
                    ],
                },
            ],
        },
    ],
};
